import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatSelectChange } from "@angular/material/select";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-multi-select-search",
  templateUrl: "./multi-select-search.component.html",
  styleUrls: ["./multi-select-search.component.scss"]
})
export class MultiSelectSearchComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() options: any[] = [];
  @Input() selectedValue: string[] = [];
  @Input() displayName: string;
  @Input() displayValue: string;
  @Input() isRequired = false;
  @Input() isNoneRequired = false;
  @Input() isSelectAllRequired = false;
  @Input() isSelectTriggerRequired = false;
  @Input() selectTriggerValue: string[] = [];
  @Input() isReadOnlyDropdown = false;
  @Output() optionSelected = new EventEmitter();

  @ViewChild("allSelected") private allSelected: MatOption;

  public searchText: string;
  public searchCtrl: FormControl = new FormControl();
  public multiSelectCtrl: FormControl = new FormControl();
  public filteredOptions: any[] = [];
  public selectedSequencedItem: any[] = [];
  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions = [...this.options.slice()];
    const selectedOptions: string[] = this.getSelectedOptions();
    this.multiSelectCtrl.setValue(selectedOptions);

    if (!this.isSelectTriggerRequired) {
      this.selectedSequencedItem = this.getDisplayOptions();
    }

    this.searchCtrl.valueChanges.pipe(debounceTime(500)).pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterOptionList();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterOptionList() {
    if (!this.options) {
      return;
    }
    this.searchText = this.searchCtrl?.value?.trim()?.toLowerCase() ? this.searchCtrl.value.trim().toLowerCase() : "";
    this.getFilteredOptions();
  }

  private getSelectedOptions = (): string[] => {
    const checkedOptions: string[] = [];
    if (this.selectedValue && this.selectedValue.length > 0) {
      this.selectedValue.forEach((value: string) => {
        const opt: any[] = this.options?.filter(option => option[this.displayValue] === value);
        if (opt && opt.length > 0) {
          checkedOptions.push(opt[0][this.displayValue]);
        }
      });
    }
    return checkedOptions;
  }

  private getDisplayOptions = (): any[] => {
    const checkedOptions: any[] = [];
    this.filteredOptions?.forEach(option => {
      if (option && this.multiSelectCtrl.value
        && this.multiSelectCtrl.value.length > 0
        && this.multiSelectCtrl.value.includes(option[this.displayValue])) {
        const optionIndex = this.multiSelectCtrl.value.indexOf(option[this.displayValue]);
        if (optionIndex > -1) {
          checkedOptions[optionIndex] = option[this.displayName];
        }
      }
    });
    return checkedOptions;
  }

  private getFilteredOptions = () => {
    if (!this.searchText) {
      this.sortOptions();
      return;
    }
    const fOptions: any[] = [];
    const sOptions: any[] = [];
    this.options?.forEach(opt => {
      const optionAlreadySelected: boolean = opt && this.multiSelectCtrl.value
        && this.multiSelectCtrl.value.length > 0
        && this.multiSelectCtrl.value.includes(opt[this.displayValue]);
      if (optionAlreadySelected) {
        const optionIndex: number = this.multiSelectCtrl.value.indexOf(opt[this.displayValue]);
        if (optionIndex > -1) {
          sOptions[optionIndex] = opt;
        }
      }
      if (opt && opt[this.displayName].toLowerCase().indexOf(this.searchText) > -1 && !optionAlreadySelected) {
        fOptions.push(opt);
      }
    });
    this.filteredOptions = [...sOptions, ...fOptions];
  }

  public toggleAllSelection = () => {
    let selectedOptions: any[] = [];
    if (this.allSelected) {
      if (this.allSelected.selected) {
        this.multiSelectCtrl.setValue([...this.options.map(item => item[this.displayValue]), "selectAll"]);
        selectedOptions = this.options.map(item => item[this.displayValue]);
      } else {
        this.multiSelectCtrl.setValue([]);
      }
    }
    this.optionSelected.emit(selectedOptions);
  }

  public toggleOneOption = (value: any) => {
    let selectedOptions: any[] = [];
    const multiSelectValue: any[] = this.multiSelectCtrl && this.multiSelectCtrl.value
      ? this.multiSelectCtrl.value : [];
    if (this.allSelected) {
      if (this.allSelected.selected) {
        this.allSelected.deselect();
        this.sortOptions();
        this.optionSelected.emit(multiSelectValue);
        return false;
      }
      if (multiSelectValue.length === this.options.length) {
        this.allSelected.select();
      } else {
        this.sortOptions();
      }
    } else {
      if (multiSelectValue.length > 1) {
        const sOptions: any[] = [...multiSelectValue.slice()];
        const index: number = multiSelectValue.indexOf(value);
        sOptions.splice(index, 1);
        sOptions.push(value);
        this.multiSelectCtrl.setValue(sOptions);
      }
      this.sortOptions();
    }
    selectedOptions = multiSelectValue.filter(o => o !== "selectAll");
    this.optionSelected.emit(selectedOptions);
  }

  public openedChange = (isPanelOpen: boolean) => {
    const multiSelectValue: any[] = this.multiSelectCtrl && this.multiSelectCtrl.value
      ? this.multiSelectCtrl.value : null;
    const hasMultiSelect: boolean = !!multiSelectValue;
    const hasOptions: boolean = !!this.options;
    const excludeSelectAllOptions = hasMultiSelect && multiSelectValue.filter(o => o !== "selectAll");
    if (this.allSelected && hasMultiSelect && hasOptions && excludeSelectAllOptions.length === this.options.length) {
      this.allSelected.select();
    } else {
      if (isPanelOpen && multiSelectValue && multiSelectValue.length > 0) {
        this.sortOptions();
      }
    }
  }

  public closedChange = () => {
    if (this.allSelected && this.allSelected.selected) {
      this.multiSelectCtrl.setValue([...this.options.map(item => item[this.displayValue])]);
    }
  }

  public changeOptionSequence(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.multiSelectCtrl.value, event.previousIndex, event.currentIndex);
    moveItemInArray(this.filteredOptions, event.previousIndex, event.currentIndex);
    let selectedOptions: any[] = [];
    if (this.multiSelectCtrl && this.multiSelectCtrl.value) {
      selectedOptions = this.multiSelectCtrl.value.filter(o => o !== "selectAll");
    }
    this.optionSelected.emit(selectedOptions);
  }

  public sortOptions = () => {
    const selectedItem: any[] = [];
    const unselectedItem: any[] = [];
    this.selectedSequencedItem = [];
    const options: any[] = this.searchText ? this.filteredOptions : this.options;
    options?.forEach(option => {
      if (option && this.multiSelectCtrl.value
        && this.multiSelectCtrl.value.length > 0
        && this.multiSelectCtrl.value.includes(option[this.displayValue])) {
        const optionIndex: number = this.multiSelectCtrl.value.indexOf(option[this.displayValue]);
        if (optionIndex > -1) {
          selectedItem[optionIndex] = option;
          this.selectedSequencedItem[optionIndex] = option[this.displayName];
        }
      } else {
        unselectedItem.push(option);
      }
    });
    this.filteredOptions = [...selectedItem, ...unselectedItem];
  }
}
