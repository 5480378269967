import { TriggerTypesList } from "@app/trigger/types/trigger-types-list";
import { AcceptedMedia } from "../types/accepted-media";
import { RuleConditions } from "../types/rule-conditions";

export class TriggerConstant {
  public readonly CLIENT = "CLIENT";
  public readonly MASTER = "MASTER";
  public readonly COPY = "COPY";
  public readonly CHAMELEON = "Chameleon";
  public readonly UNDEFINED_DATA = "-";
  public readonly APPROVED = "Approved";
  public readonly SUBMITTED = "Submitted";

  public readonly ERROR_TRIGGER_CREATE = "Trigger could not be created";
  public readonly ERROR_TRIGGER_UPDATE = "Trigger could not be updated";
  public readonly ERROR_TRIGGER_DISABLE = "Trigger could not be disabled";
  public readonly ERROR_TRIGGER_ENABLE = "Trigger could not be enabled";
  public readonly ERROR_TRIGGER_TEMPLATED = "A template of the trigger could not be created";
  public readonly ERROR_TRIGGER_UNSAVED = "Trigger is not yet saved";
  public readonly ERROR_TEMPLATE_TRIGGER_UNSAVED = "Trigger must be saved before creating a template from it";
  public readonly SUCCESS_TRIGGER_CREATE = "Trigger created successfully";
  public readonly SUCCESS_TRIGGER_UPDATE = "Trigger updated successfully";
  public readonly SUCCESS_TRIGGER_TEMPLATED = "A template of the trigger successfully created";
  public readonly ERROR_TRIGGER_RETRIEVE = "Trigger could not be retrieved";
  public readonly SUCCESS_TRIGGER_DELETE = "Trigger deleted successfully";
  public readonly ERROR_TRIGGER_DELETE = "Trigger could not be deleted";
  public readonly CONFIRM_TRIGGER_DELETE = "Are you sure to delete the selected Trigger ?";
  public readonly DELETE_RULE_TEXT = "Minimum 1 rule is mandatory";
  public readonly ADD_RULE_TEXT = "Minimum 1 and Maximum 3 rules are allowed";
  public readonly IMAGE_UPLOAD_SUCCESS_TEXT = "Image uploaded successfully!";
  public readonly IMAGE_UPLOAD_FAILED_TEXT = "Image failed to upload!";
  public readonly FILE_UPLOAD_SUCCESS_TEXT = "File uploaded successfully!";
  public readonly FILE_UPLOAD_FAILED_TEXT = "File failed to upload!";
  public readonly TRIGGER_ENABLE_SUCCESS = "Trigger enabled successfully";
  public readonly TRIGGER_DISABLE_SUCCESS = "Trigger disabled successfully";
  public readonly VALID_PHONE_NUMBER_WARNING = "Please enter valid Phone Number";
  public readonly BUTTON_MANDATORY_FIELD_MSG = "Please fill mandatory fields in Button display section. Button Domain Path is Required";
  public readonly BUTTON_DOMAIN_PREFIX_MANDATORY_MSG = "Please fill either Button Domian Prefix or Button Domain. One of them is Required";
  public readonly KEYWORDS_TO_AVOID_MSG = "Please dont use application specific keywords(like chat, landing, dashboard, feedback, front) in Button Domian path.";
  public readonly ERROR_COMMON_SECTION = "Linking trigger is not allowed when common section is enabled";
  public readonly ERROR_IMPORT_LINK = "Please select any trigger to import or link";
  public readonly ERROR_SMART_ASSISTANCE = "Smart assistance values are not allowed when common section is enabled. Please remove smart assistance domain and smart assistance domain prefix";
  public readonly ERROR_INVALID_TRIGGER = "Invalid initaiation trigger. Please recheck it";
  public readonly DELETED_LINKED_TRIGGER_MSG = "The trigger had linked triggers that are now deleted.";
  public readonly BUTTON_TITLE_MANDATORY = "Title is mandatory";
  public readonly DELETE_TEXT = "This will permanently delete the Trigger: ";
  public readonly DELETE_CONFIRMATION_TEXT = "Are you sure you'd like to proceed with the deletion?";
  public readonly DELETE_CONFIRM_BUTTON_TEXT = "Yes, Delete it";

  public readonly LANDING_BTN_UPDATE = "Update";
  public readonly LANDING_BTN_NEW = "New";
  public readonly LANDING_BTN_DELETE = "Delete";
  public readonly LANDING_BTN_CANCEL = "Cancel";
  public readonly NODE_1 = "node-1";
  public readonly LANDING_PAGE = "landingPage";
  public readonly LANDING_COMPONENT = "landingComponent";
  public readonly NONE = "none"
  public readonly LINKED_TRIGGER = "LINKED_TRIGGER";
  public readonly SUBMIT = "submit";
  public readonly CREATE = "CREATE";
  public readonly UPDATE = "UPDATE";
  public readonly CONVERSATION_JNY = "Conversation"
  public readonly FORM_JNY = "Form";
  public readonly SPLASH_SCREEN = "splash screen";
  public readonly SMART_ASSISTANCE = "smartAssistance";

  public readonly EMAIL_TRIGGER = "EmailTrigger";
  public readonly INIT_TRIGGER = "InitiationTrigger";
  public readonly AI_MONITORING_TRIGGER = "AIMonitoringTrigger";
  public readonly MULTIMEDIA_TRIGGER = "MultimediaTrigger";
  public readonly API_TRIGGER = "APITrigger";
  public readonly FEEDBACK_TRIGGER = "FeedbackTrigger";
  public readonly MOOD_RESPONSE_TRIGGER = "MoodResponseTrigger";
  public readonly LIVE_CHAT_TRIGGER = "LiveChatTrigger";
  public readonly WF_MONITORING_TRIGGER = "WFMonitoringTrigger";
  public readonly LIVECHAT_MONITORING = "livechat-monitoring";
  public readonly AI_MONITORING = "ai-monitoring";
  public readonly WORKFLOW_MONITORING = "workflow-monitoring";
  public readonly MESSAGE_TRIGGER = "MessageTrigger";
  public readonly API_CALL = "apiCall";

  public readonly BUTTON_LOOK_EDGY = "edgy";
  public readonly BUTTON_LOOK_CARD = "card";
  public readonly BUTTON_LOOK_CIRCLE = "circle";
  public readonly BUTTON_LOOK_TRANSPARENT = "transparent";
  public readonly BUTTON_TRANSPARENT = "transparent";
  public readonly BUTTON_LOOK_HORIZONTAL = "horizontal";
  public readonly BUTTON_LOOK_EXPANDABLE = "expandable";
  public readonly BUTTON_LOOK_POPUP = "popup";
  public readonly BUTTON_LOOK_INPUT = "inputType";

  public readonly MM_TYPE_DOCUMENT = "document";
  public readonly MM_TYPE_IMAGE = "image";
  public readonly MM_TYPE_VIDEO = "video";
  public readonly DEFAULT_SCTN_WIDTH = "100%";
  public readonly MM_URL = "multimediaUrl";
  public readonly MM_AUTOPLAY = "autoplay";
  public readonly MM_DEFAULT_DISP_DOCS = "defaultDisplayDocs";
  public readonly TRIGGER_ID = "triggerId"
  public readonly STATUS_DISABLED = "Disabled";
  public readonly FILES = "files";
  public readonly EMAIL_TYPE_ADMIN = "admin";
  public readonly EMAIL_TYPE_CUSTOMER = "customer";
  public readonly EMAIL_CUST_UPLOADED_DOCS_URL = "@CustomerUploadedDocsUrl";
  public readonly QUEUES = "queues";
  public readonly TRIGGER_NAVIGATION = "/triggers";
  public readonly NODE_ID_NODE_1 = "node-1";
  public readonly TRIGGER_NAME = "triggerName";
  public readonly TRIGGER_TYPE = "triggerType";
  public readonly MODIFIED_BY = "modifiedBy";
  public readonly DISABLED = "disabled";
  public readonly DELETE_REQUESTED = "delete_requested";
  public readonly UPDATED_TIMESTAMP = "updatedTimestamp";
  public readonly NAVIGATE_TO_TRIGGER_CREATE = "./trigger-create";

  public readonly MOOD_HAPPY = "Happy";
  public readonly MOOD_SAD = "Sad";
  public readonly MOOD_ANGRY = "Angry";
  public readonly MOOD_NEUTRAL = "Neutral";

  public readonly SCENARIOS = "scenarios";
  public readonly RULES = "rules";
  public readonly OPERAND = "operand";
  public readonly AND = "&&";
  public readonly OR = "OR";
  public readonly LOGICAL_AND = "AND";
  public readonly LOGICAL_OR = "||";
  public readonly EQUALS_OPERAND = "==";
  public readonly NOT_EQUALS_OPERAND = "!=";
  public readonly GREATER_THAN_OPERAND = ">";
  public readonly LESS_THAN_OPERAND = "<";
  public readonly CONTAINS_OPERAND = ".contains";
  public readonly CONTAINS = "contains";
  public readonly AMPERSAND = "&";
  public readonly PIPE = "|";

  public readonly NEXT_ACTION_EXISTING_STACK = "existingStack";
  public readonly IMAGE_URL = "imageUrl";
  public readonly CONTROLS = "controls";
  public readonly SORT = "sort";
  public readonly FILTER = "filter";
  public readonly COLLECTABLE_KEYS = "collectableKeys";
  public readonly FIELD_TYPE = "fieldType";
  public readonly UI_CONTENT_LOOKUP = "lookup";
  public readonly ADD_CONTROL = "AddControl";
  public readonly HAMBURGER = "hamburger";
  public readonly SPLASH_SCREEN_COMPONENT = "splashScreenComponent";
  public readonly AMOUNT = "Amount";
  public readonly SECTION = "Section";
  public readonly CENTER = "center";
  public readonly BACKGROUND_SRC = "backgroundSrc";
  public readonly BACKGROUND_ASSET_ID = "bgAssetId";
  public readonly BUTTONS = "buttons";
  public readonly ADDITIONAL_SCENARIOS = "additionalScenarios";
  public readonly INPUT_META_PARAMETERSJ = "inputMetaParametersJ";
  public readonly OUTPUT_META_PARAMETERSJ = "outputMetaParametersJ";
  public readonly DISPLAY_META_PARAMETERSJ = "displayMetaParameterJ";
  public readonly SUCCESS_STRUCTUREJ = "successStructureJ";
  public readonly FAILURE_STRUCTUREJ = "failureStructureJ";
  public readonly UI_COMPONENT = "uiComponent";
  public readonly FCTRL_DISPLAY_COMPONENT = "displayComponent";
  public readonly FCTRL_WAIT_TO_PROCEED = "waitToProceed";
  public readonly NEW_SECTION = "New Section";
  public readonly BTN_PLACEMENT_AFTER = "afterCanvas"

  public readonly BOOLEAN = "boolean";
  public readonly STRING = "string";
  public readonly TRUE = "true";
  public readonly ACCEPTED_MEDIA: AcceptedMedia = {
    document: ".pdf,.doc,docx,.csv,.xls,.xlsx,.ppt,.pptx",
    image: ".jpg,.jpeg,.png,.gif,.tif,.tiff,.svg",
    video: ".mp4,.avi"
  }

  public readonly fieldTypes: RuleConditions[] = [
    { label: "Text", value: "text" },
    { label: "Number", value: "number" },
    { label: "Percentage", value: "percentage" },
    { label: "Email", value: "email" },
    { label: "Mobile", value: "mobile" },
    { label: "Currency", value: "currency" },
    { label: "Address", value: "address" },
    { label: "City", value: "city" },
    { label: "State", value: "state" },
    { label: "Country", value: "country" },
    { label: "Date", value: "date" },
    { label: "Time", value: "time" },
    { label: "URL", value: "url" },
    { label: "Upload", value: "upload" },
    { label: "Password", value: "password" },
    { label: "Script Link", value: "scriptLink" },
    { label: "Scriptlet", value: "scriptlet" },
    { label: "Timezone", value: "timezone" }
  ];

  public readonly RuleConditions: RuleConditions[] = [
    {
      label: "Equal to (=)",
      value: "=="
    },
    {
      label: "Not equal to (!=)",
      value: "!="
    },
    {
      label: "Contains",
      value: "contains"
    },
    {
      label: "Greater than (>)",
      value: ">"
    },
    {
      label: "Less than (<)",
      value: "<"
    }
  ];
  public readonly TRIGGER_TYPE_LIST: TriggerTypesList[] = [
    {
      name: "Initiation",
      type: "InitiationTrigger",
      checked: false
    },
    {
      name: "APIs",
      type: "APITrigger",
      checked: false
    },
    {
      name: "Email Templates",
      type: "EmailTrigger",
      checked: false
    },
    {
      name: "Message Templates",
      type: "MessageTrigger",
      checked: false
    },
    {
      name: "Multimedia",
      type: "MultimediaTrigger",
      checked: false
    },
    {
      name: "Live Chat",
      type: "LiveChatTrigger",
      checked: false
    },
    {
      name: "Feedback",
      type: "FeedbackTrigger",
      checked: false
    },
    {
      name: "AI Monitoring",
      type: "AIMonitoringTrigger",
      checked: false
    },
    {
      name: "Workflow Monitoring",
      type: "WFMonitoringTrigger",
      checked: false
    },
    {
      name: "Emotion AI",
      type: "MoodResponseTrigger",
      checked: false
    }
  ];

  public readonly TRIGGER_TYPE_OPTIONS = [
    { viewValue: "Initiation", value: "InitiationTrigger" },
    { viewValue: "API Call", value: "APITrigger" },
    { viewValue: "Email", value: "EmailTrigger" },
    { viewValue: "Multimedia", value: "MultimediaTrigger" },
    { viewValue: "Live Chat", value: "LiveChatTrigger" },
    { viewValue: "Feedback", value: "FeedbackTrigger" },
  ];
  public readonly BTN_TEXT_BRAND_FIELD_OPTIONS = [
    { viewValue: "Contact Number", value: "contactNumber" },
    { viewValue: "To Email ID", value: "toEmailID" },
    { viewValue: "Business working days and hours", value: "businessWorkingDays" },
    { viewValue: "Open Time", value: "openTiming" },
    { viewValue: "Close Time", value: "closeTiming" },
    { viewValue: "Contact Person Name", value: "contactPersonName" },
    { viewValue: "ARN", value: "arn" },
    { viewValue: "ABN", value: "abn" },
    { viewValue: "ACL", value: "acl" },
    { viewValue: "AFSL", value: "afsl" },
    { viewValue: "AFCA", value: "afca" },
    { viewValue: "CARN", value: "carn" },
    { viewValue: "Licensee Name", value: "licenseeName" },
    { viewValue: "ACN", value: "acn" },
    { viewValue: "Registered Business Name", value: "registeredBusinessName" },
    { viewValue: "Industry", value: "industry" },
    { viewValue: "Sector", value: "sector" },
    { viewValue: "Services", value: "services" },
    { viewValue: "Location", value: "location" },
    { viewValue: "Disclaimer", value: "disclaimer" },
    { viewValue: "Profile", value: "profile" },
    { viewValue: "About Me", value: "aboutMe" },
    { viewValue: "Logo Text", value: "logoText" },
    { viewValue: "Profile Text", value: "profileText" },
    { viewValue: "Display Name 1", value: "displayName1" },
    { viewValue: "Display Name 2", value: "displayName2" },
    { viewValue: "Display Name 3", value: "displayName3" },
    { viewValue: "Display Name 4", value: "displayName4" },
    { viewValue: "Display Name 5", value: "displayName5" },
    { viewValue: "Notification Message", value: "ntfMsg" },
    { viewValue: "Notification Button Text", value: "ntfBtnTxt" },
  ];

  public readonly ACTION_BRAND_FIELD_OPTIONS = [
    { viewValue: "Website URL", value: "websiteURL" },
    { viewValue: "Blog Link", value: "blogLink" },
    { viewValue: "Newsletter Link", value: "newsLetterLink" },
    { viewValue: "Appointment Link", value: "appointmentLink" },
    { viewValue: "Customer Reviews URL", value: "customerReviewsURL" },
    { viewValue: "LinkedIn Handle", value: "linkedInHandle" },
    { viewValue: "Facebook Handle", value: "facebookHandle" },
    { viewValue: "Instagram Handle", value: "instagramHandle" },
    { viewValue: "X Handle", value: "xHandle" },
    { viewValue: "Youtube Handle", value: "youtubeHandle" },
    { viewValue: "TikTok Handle", value: "tiktokHandle" },
    { viewValue: "Lead Funnel Link 1", value: "leadFunnelLink1" },
    { viewValue: "Lead Funnel Link 2", value: "leadFunnelLink2" },
    { viewValue: "Lead Funnel Link 3", value: "leadFunnelLink3" },
    { viewValue: "Lead Funnel Link 4", value: "leadFunnelLink4" },
    { viewValue: "Lead Funnel Link 5", value: "leadFunnelLink5" },
    { viewValue: "Notification Button URL", value: "ntfBtnUrl" },
  ];

  public readonly FIELD_TYPE_EMAIL = "Email";
  public readonly ENTITY_TYPE_MOBILE = "Mobile";
  public readonly FIELD_TYPE_UPLOAD = "Upload";
  public readonly DISPLAY_TYPE_LANDSCAPE = "landscape";
  public readonly DISPLAY_TYPE_PORTAIT = "portrait";
  public readonly DISPLAY_TYPE_IFRAME = "iframe";
  public readonly DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  public readonly VALIDATE_AT = [{ name: "Client Side", value: "CS" }, { name: "Server Side", value: "SS" }];
  public readonly NONE_EVALUATE_DEFAULT = "none";
  public readonly DEFAULT_IMAGE_FORMATS = ["png", "jpg"];
  public readonly COUNTRY_CODES = ["+61", "+91"];
  public readonly DEFAULT_FIELD_TYPE_TEXT = "text";
  public readonly SUCCESS = "success";
  public readonly ERROR = "error";
  public readonly WARNING = "warning";
  public readonly UPLOADED_FILE = "uploadedFile";
  public readonly FILE_NAME = "fileName";
  public readonly CLIENT_ID = "clientId";
  public readonly CATEGORY = "category";
  public readonly COLOR_WHITE = "white";
  public readonly SPLASH_SCREEN_COLUMNS = ["buttonsDisplayText", "buttonAction", "actionValue"];
  public readonly ROLE_PLATFORM_ADMIN = "ROLE_PLATFORM_ADMIN";

  public readonly PRE_API_EXPR = "preAPIExpressionsJ";
  public readonly POST_API_EXPR = "postAPIExpressionsJ";
  public readonly PORTRAIT = "portrait";
  public readonly MOBILE = "mobile";
  public readonly DATE = "date";
  public readonly TAB = "tab";
  public readonly CHAT = "chat";
  public readonly NEW_TAB = "newTab";
  public readonly LANDING = "landing";
  public readonly DASHBOARD = "dashboard";
  public readonly FEEDBACK = "feedback";
  public readonly FRONT = "front";
  public readonly VALUES = "values";
  public readonly FILE_TYPE = "fileType";
  public readonly REJECT_PARAMS = "rejectParams";
  public readonly VALIDATE_AT_KEY = "validateAt";
  public readonly SUPPORTED_COUNTRIES = "supportedCountries";
  public readonly SUPPORTED_CURRENCIES = "supportedCurrencies";
  public readonly INITIATION = "initiation";
  public readonly CONVERSATION_STACK = "conversationStack";
  public readonly FORMSTACK = "formStack";
  public readonly INTENT = "intent";
  public readonly LIVECHAT = "livechat";
  public readonly GOTO_SECTION_BTN = "goToSectionBtn";
  public readonly REVIEW = "review";
  public readonly CUSTOM_SCRIPT = "customScript";
  public readonly SECTION_TAB = "section";
  public readonly SECTION_AUTO = "sectionAuto";
  public readonly URL = "url";
  public readonly EMAIL = "email";
  public readonly HTTPS = "https://";
  public readonly AFTER_INPUT = "afterInput";
  public readonly AUSTRALIA_COUNTRY_CODE = "+61";
  public readonly VALUE = "value";
  public readonly IMPORT = "import";
  public readonly LINK = "link";
  public readonly ICON_INFO = "info";
  public readonly BUTTON_PATHS_TO_AVOID = [
    "chat",
    "landing",
    "dashboard",
    "feedback",
    "front",
    "/chat",
    "/landing",
    "/dashboard",
    "/feedback",
    "/front"
  ];

  public readonly TRIGGER_ID_FCTRL = "triggerId";
  public readonly TRIGGER_NAME_FCTRL = "triggerName";
  public readonly TRIGGER_TYPE_FCTRL = "triggerType";
  public readonly OWNERSHIP_FCTRL = "ownership";
  public readonly COMMON_SECTION = "commonSection";
  public readonly LAUNCH = "launch";
  public readonly ACTION = "action";
  public readonly NEXT_ACTION_NAME = "nextActionName";

  public readonly ID_BUTTON_DIV = "buttonDiv";
  public readonly ID_SECTION_DIV = "sectionDiv";
  public readonly ID_DATA = "data-id";
  public readonly ID_MAIN = "main";
  public readonly ID_NODE = "node-";

  public readonly CLS_NODE_ITEM = "node-item";
  public readonly CLS_DROP = "drop-";

  public readonly ACT_BEFORE = "before";
  public readonly ACT_AFTER = "after";
  public readonly ACT_INSIDE = "inside";

  public readonly UI_POLICY_MODULE_NAME_ET_MANAGEMENT = "et-management";
  public readonly FULL_CLIENT_NAME = "fullClientName";
  public readonly COUNTRY_CODE_AND_NAME = "countryCodeAndName";
  public readonly CURRENCY_ABBR_AND_NAME = "currencyAbbrAndName";
  public readonly SELECT_ALL = "selectAll";
  public readonly COUNTRY_NAME = "countryName";
  public readonly CURRENCY_NAME = "currencyName";
  public readonly INVITE = "INVITE";
  public readonly TRIGGER_TAB = "triggerTab";
  public readonly TRIGGER_CREATE = "triggerCreate";
  public readonly CLIENT_DEPT_ID_LIST = "clientDeptIdList";

  public readonly MIN_TRIGGER_ID = 1;

  public readonly PATTERN_NEW_LINE = /(\n)/gm;
  public readonly PATTERN_RETURN_CHAR = /(\r)/gm;
  public readonly PATTERN_HORIZONAL_TABS = /(\t)/gm;
  public readonly MULTIPLE_EMAIL_PATTERN_REGEX = /^((?:(?:[a-zA-Z0-9_\-\.]+)@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(?:(?:[a-zA-Z0-9\-]+\.)+))(?:[a-zA-Z]{2,4}|[0-9]{1,3})(?:\]?)(?:\s*;\s*|\s*$))*)$/;
  public readonly MULTIPLE_PHONE_NO_PATTERN_REGEX = /^((\+[1-9])([0-9]){8,})(;\s?((\+[1-9])([0-9]){8,}))*$/;
  public readonly SPACE_REGEX = /&nbsp;/g;

  public readonly BA_GO_TO_SECTION = "goToSectionBtn";
  public readonly BA_NONE = "none";
  public readonly BA_SECTION_AUTO = "sectionAuto";
  public readonly BA_BUTTON_AUTO = "buttonAuto";
  public readonly BA_CHAMELEON = "chameleon";


  public readonly Z_INDEX_99 = "99";
  public readonly Z_INDEX_9991 = "9991";
  public readonly PERCENT_100 = "100%";
  public readonly PIXEL = "px";
  public readonly VIEW_HEIGHT = "vh";
  public readonly PX_100 = "100px";
  public readonly PX_0 = "0px";
  public readonly VERTICAL = "vertical";
  public readonly LEFT = "left";
  public readonly RIGHT = "right";
  public readonly HEADER = "header";
  public readonly FOOTER = "footer";
  public readonly HOVER = "hover";
  public readonly POPUP = "popup";
  public readonly SEARCH = "search";
  public readonly NORMAL = "normal";
  public readonly BODY = "body";
  public readonly BG_NO_REPEAT = "no-repeat";
  public readonly DEFAULT_BG_POSITION = "center";
  public readonly DEFAULT_BTN_POSITION = "relative";
  public readonly DEFAULT_BTN_MARGIN = "0px";
  public readonly DEFAULT_BG_SIZE = "cover";
  public readonly SCREEN_1025 = 1025;
  public readonly SCREEN_1125 = 1125;
  public readonly SCREEN_1185 = 1185;
  public readonly SCREEN_1186 = 1186;
  public readonly SCREEN_1250 = 1250;
  public readonly SCREEN_1320 = 1320;
  public readonly SCREEN_1366 = 1366;
  public readonly BUTTON_CIRCLE = "circle";
  public readonly BT_COLOR = "colour";
  public readonly CUSTOM_SCRIPTS = "customScripts";
  public readonly CONTENTS = "contents";
  public readonly ANALYTIC_SCRIPTS = "analyticScripts";

  public readonly FONT_REDUCE_8 = 8;
  public readonly FONT_REDUCE_20 = 20;
  public readonly FONT_REDUCE_30 = 30;
  public readonly FONT_REDUCE_47 = 47;

  public readonly DEFAULT_BTN_PADDING = "2px 10px";

  public readonly TRG_CREATE_TYPE = "TRG_CREATE_TYPE";
  public readonly TRG_TAB_TYPE = "TRG_TAB_TYPE";

  public readonly BUTTON_CARD = "card";
  public readonly BUTTON_POPUP = "popup";
  public readonly VIEW_WIDTH = "vw";
  public readonly HTML_ELE_DIV = "div";
  public readonly AUTO = "auto";


  constructor() { }
}
