import { Injectable } from "@angular/core";
import { flavour } from "@app/core/services/endpoint-list/flavour";

@Injectable({
  providedIn: "root"
})
export class EndpointListService {

  public readonly getUserList: string;
  public readonly getFilteredUserList: string;
  public readonly getDepartments: string;
  public readonly getClientDepartments: string;
  public readonly getMergedDepartments: string;
  public readonly getModules: string;
  public readonly getStatus: string;
  public readonly createUser: string;
  public readonly inviteUser: string;
  public readonly getUserDetails: string;
  public readonly rejectUser: string;
  public readonly disableUser: string;
  public readonly enableUser: string;
  public readonly deleteUser: string;
  public readonly approveUser: string;
  public readonly updateUser: string;
  public readonly getAllRoles: string;
  public readonly updateAgent: string;
  public readonly getUsersInGroup: string;
  public readonly getDomains: string;
  public readonly getAllPlatformRoles: string;
  public readonly getSentinelUser: string;
  public readonly getSentinelDetails: string;
  public readonly createSentinelUser: string;
  public readonly updateSentinelUser: string;
  public readonly getClientAgents: string;
  public readonly searchClients: string;
  public readonly googleAuthURL: string;
  public readonly msAuthURL: string
  public readonly zohoConsentURL: string;
  public readonly resetUserPassword: string;

  public readonly getClientList: string;
  public readonly getClientDetail: string;
  public readonly createClient: string;
  public readonly updateClient: string;
  public readonly transitionClientStatus: string;
  public readonly getChannels: string;
  public readonly getCountryCodes: string;
  public readonly getCurrencies: string;
  public readonly getLanguages: string;
  public readonly getAllTimezones: string;
  public readonly getRestrictionPolicy: string;
  public readonly saveRestrictionPolicy: string;
  public readonly enableDisable: string;
  public readonly deleteClient: string;
  public readonly deleteDeptApiCall: string;
  public readonly getHostList: string;
  public readonly createDepartemnt: string;
  public readonly getAdminUsers: string;
  public readonly getClientDomainDirs: string;
  public readonly addClientDomainDirs: string;
  public readonly deleteClientDomainDirs: string;
  public readonly getClientDataRetention: string;
  public readonly getClientMetaData: string;
  public readonly getAllFromEmails: string;
  public readonly getUserDepartments: string;

  public readonly getAgentList: string;
  public readonly listAllAgents: string;
  public readonly getActiveListing: string;
  public readonly getAgentStatus: string;
  public readonly getAgentsDepartments: string;
  public readonly changeAgentStatus: string;
  public readonly getAiAssistanceResponse: string;
  public readonly getManualResponseFromAi: string;

  public readonly getAllTriggers: string;
  public readonly createTrigger: string;

  public readonly connectRTM: string;
  public readonly connectAgentRTM: string;

  public readonly createCMSUser: string;
  public readonly updateCMSUser: string;
  public readonly inviteCMSUser: string;
  public readonly getCMSUserDetails: string;
  public readonly disableCMSUser: string;
  public readonly enableCMSUser: string;
  public readonly approveCMSUser: string;
  public readonly rejectCMSUser: string;
  public readonly deleteCMSUser: string;

  // Journey Builder
  public readonly getJourneyList: string;
  public readonly getWorkflowList: string;
  public readonly getSelectedJourney: string;
  public readonly getIntents: string;
  public readonly getBehaviours: string;
  public readonly getNLPEngines: string;
  public readonly createJourney: string;
  public readonly updateJourney: string;
  public readonly uploadJourney: string;
  public readonly duplicateJourney: string;
  public readonly updateStatus: string;
  public readonly deleteJourney: string;
  public readonly getSections: string;
  public readonly createSection: string;
  public readonly getMilestones: string;
  public readonly createMilestone: string;
  public readonly getStackList: string;
  public readonly viewJbById: string;
  public readonly fetchAllWorkflows: string;
  public readonly getClientWorkflows: string;

  // Entity
  public readonly getEntities: string;
  public readonly addEntity: string;
  public readonly updateEntity: string;
  public readonly deleteEntity: string;

  // Behaviour
  public readonly getBehaviorListApiCall: string;
  public readonly deleteBehaviour: string;
  public readonly disableApiCall: string;
  public readonly copyApiCall: string;
  public readonly createBehaviour: string;
  public readonly makeBehaviourTemplate: string;
  public readonly uploadFile: string;
  public readonly getBehaviourDetail: string;
  public readonly saveSEOFile: string;
  public readonly updateSEOFile: string;
  public readonly publishSEOFile: string;
  public readonly getSEOFileInfo: string;
  public readonly fetchAllBehaviours: string;
  public readonly listAllowedBehaviours: string;
  public readonly linkedBehaviours: string;
  public readonly getBehaviourDomains: string;
  public readonly getMultiPageByDomainDirectory: string;

  // NLP
  public readonly getNlpListApiCall: string;
  public readonly getNlpDetails: string;
  public readonly saveNlpEngine: string;
  public readonly updateNLPAsset: string;
  public readonly updateNlpEQPrompt: string;
  public readonly getIntentList: string;
  public readonly getIntentsByBehavior: string;
  public readonly approveNLP: string;
  public readonly rejectNLP: string;
  public readonly changeNlpStatus: string;
  public readonly getIntentDetails: string;
  public readonly getSentimentDetails: string;
  public readonly createSentiment: string;
  public readonly getBehaviourNlpEngine: string;
  public readonly fetchAllNlpEngines: string;
  public readonly updateNlpEngine: string;
  public readonly updateNLPProperties: string;
  public readonly updateNlpSettings: string;
  public readonly getNlpEnginesLight: string;

  // intent specific
  public readonly getINTIntentList: string;
  public readonly getINTIntentDetails: string;
  public readonly saveIntent: string;
  public readonly approveIntent: string;
  public readonly approveResponse: string;
  public readonly checkDuplicateUtterance: string;
  public readonly changeIntentStatus: string;
  public readonly rejectIntent: string;
  public readonly searchUtterance: string;
  public readonly getAllClientIntents: string;

  // Audit
  public readonly getAuditLogs: string;
  public readonly fetchAuditEntries: string;
  public readonly getAuditDetails: string;
  public readonly getModuleList: string;

  // Analytics
  public readonly getWorkflow: string;
  public readonly getLivechat: string;
  public readonly getAI: string;
  public readonly getForms: string;
  public readonly getConsolidatedMetadata: string;
  public readonly getWebpage: string;
  public readonly getEvent: string;

  // Api Definition
  public readonly externalApiDefinitions: string;
  public readonly allDefinitions: string;
  public readonly definitionDetails: string;
  public readonly updateApiDefinition: string;
  public readonly deleteApiDefinition: string;
  public readonly apiDefinition: string;
  public readonly enableDefinition: string;
  public readonly disableDefinition: string;
  public readonly fetchAllDefinitions: string;
  public readonly listAllowedApiDefinition: string;

  // Entity and Triggers
  public readonly allentities: string;
  public readonly triggersOwned: string;
  public readonly retrieveTrigger: string;
  public readonly trigger: string;
  public readonly makeTriggerTemplate: string;
  public readonly updateTrigger: string;
  public readonly getAllTriggerTypes: string;
  public readonly deleteTrigger: string;
  public readonly listMasterTriggers: string;
  public readonly enableTrigger: string;
  public readonly disableTrigger: string;
  public readonly landingPageTriggers: string;
  public readonly fetchAllTriggersOwned: string;
  public readonly triggersOwnedByClient: string;
  public readonly fetchResolvedTriggerInfo: string;
  public readonly listAllowedTriggers: string;
  public readonly fetchAllEntities: string;

  // Machine Learning
  public readonly getPinnedUtterances: string;
  public readonly getUnhandledUtterances: string;
  public readonly updateUnhandledUtterances: string;
  public readonly deleteUnhandledUtterances: string;
  public readonly assignUnhandledUtterances: string;
  public readonly deletePinnedUtterances: string;
  public readonly updatePinnedUtterances: string;
  public readonly assignPinnedUtterances: string;
  public readonly delMulUnhandledUtterances: string;
  public readonly delMulPinnedUtterances: string;
  public readonly getBehavioursByIntent: string;

  public readonly getNlpByBehavior: string;

  //Asset Hub
  public readonly getAssetList: string;
  public readonly listAssetsUnsafe: string;
  public readonly createAssetList: string;
  public readonly createAssetFromCloud: string;
  public readonly updateAssetList: string;
  public readonly updateAssetFromCloud: string;
  public readonly deleteAssetList: string;
  public readonly disableAsset: string;
  public readonly enableAsset: string;
  public readonly fetchAllAssets: string;
  public readonly listLibraryAssets: string;
  public readonly listUnsplashImages: string;
  public readonly uploadFileFromCloud: string;
  public readonly retrieveAsset: string;

  //RTMC
  public readonly listActiveSessions: string;
  public readonly agentStatistics: string;
  public readonly agentWorkload: string;
  public readonly listAgents: string;
  public readonly listActiveChannels: string;

  // Chameleon Policy Management
  public readonly retrievePoliciesByType: string;
  public readonly listPolicies: string;
  public readonly getManagedElements: string;
  public readonly getManagedMethods: string;
  public readonly createPolicy: string;
  public readonly retrieveCMSPolicies: string;
  public readonly getPolicyDetails: string;
  public readonly updatePolicy: string;
  public readonly resetCMSUserPassword: string;

  // Session Mgmt
  public readonly searchSolrSessions: string;
  public readonly retrieveSession: string;
  public readonly extractForm: string;
  public readonly suggestions: string;
  public readonly retrieveAgentName: string;
  public readonly sessionsFacet: string;
  public readonly addMultiEntityValue: string;
  public readonly storeFormAuth: string;
  public readonly submitFormAuth: string;
  public readonly publishPushNotification: string;
  public readonly getPushNotifications: string;
  public readonly retrieveSnapshotDetails: string;
  public readonly evaluateEntityValueAuth: string;
  public readonly retrieveAllEtValsDecrypted: string;
  public readonly deleteSolrSessions: string;

  constructor() {
    // user management endpoints
    this.getFilteredUserList = flavour.modUM.getFilteredUserList;
    this.getDepartments = flavour.modUM.getDepartments;
    this.getClientDepartments = flavour.modUM.getClientDepartments;
    this.getMergedDepartments = flavour.modUM.getMergedDepartments;
    this.getModules = flavour.modUM.getModules;
    this.getStatus = flavour.modUM.getStatus;
    this.createUser = flavour.modUM.createUser;
    this.inviteUser = flavour.modUM.inviteUser;
    this.getUserDetails = flavour.modUM.getUserDetails;
    this.rejectUser = flavour.modUM.rejectUser;
    this.disableUser = flavour.modUM.disableUser;
    this.enableUser = flavour.modUM.enableUser;
    this.deleteUser = flavour.modUM.deleteUser;
    this.approveUser = flavour.modUM.approveUser;
    this.updateUser = flavour.modUM.updateUser;
    this.getAllRoles = flavour.modUM.getAllRoles;
    this.updateAgent = flavour.modUM.updateAgent;
    this.getUsersInGroup = flavour.modUM.getUsersInGroup;
    this.getAllPlatformRoles = flavour.modUM.getAllPlatformRoles;
    this.getSentinelUser = flavour.modUM.getSentinelUser;
    this.getSentinelDetails = flavour.modUM.getSentinelDetails;
    this.createSentinelUser = flavour.modUM.createSentinelUser;
    this.updateSentinelUser = flavour.modUM.updateSentinelUser;
    this.resetUserPassword = flavour.modUM.resetUserPassword;

    // client management endpoints
    this.getClientList = flavour.modCL.getClientList;
    this.getClientDetail = flavour.modCL.getClientDetail;
    this.createClient = flavour.modCL.createClient;
    this.updateClient = flavour.modCL.updateClient;
    this.transitionClientStatus = flavour.modCL.transitionClientStatus;
    this.getModules = flavour.modCL.getModules;
    this.getChannels = flavour.modCL.getChannels;
    this.getDepartments = flavour.modCL.getDepartments;
    this.getRestrictionPolicy = flavour.modCL.getRestrictionPolicy;
    this.saveRestrictionPolicy = flavour.modCL.saveRestrictionPolicy;
    this.getCountryCodes = flavour.modCL.getCountryCodes;
    this.getCurrencies = flavour.modCL.getCurrencies;
    this.getLanguages = flavour.modCL.getLanguages;
    this.getAllTimezones = flavour.modCL.getAllTimezones;
    this.enableDisable = flavour.modCL.enableDisable;
    this.deleteClient = flavour.modCL.deleteClient;
    this.deleteDeptApiCall = flavour.modCL.deleteDeptApiCall;
    this.getHostList = flavour.modCL.getHostList;
    this.createDepartemnt = flavour.modCL.createDepartemnt;
    this.getUserList = flavour.modCL.getUserList;
    this.getAdminUsers = flavour.modCL.getAdminUsers;
    this.getClientDomainDirs = flavour.modCL.getClientDomainDirs;
    this.addClientDomainDirs = flavour.modCL.addClientDomainDirs;
    this.deleteClientDomainDirs = flavour.modCL.deleteClientDomainDirs;
    this.getClientDataRetention = flavour.modCL.getClientDataRetention;
    this.getClientMetaData = flavour.modCL.getClientMetaData;
    this.getAllFromEmails = flavour.modCL.getAllFromEmails;
    this.getClientAgents = flavour.modCL.getClientAgents;
    this.searchClients = flavour.modCL.searchClients;
    this.googleAuthURL = flavour.modCL.googleAuthURL;
    this.msAuthURL = flavour.modCL.msAuthURL;
    this.zohoConsentURL = flavour.modCL.zohoConsentURL;
    this.getDomains = flavour.modCL.getDomains;
    this.getUserDepartments = flavour.modCL.getUserDepartments;

    this.createCMSUser = flavour.modCL.createCMSUser;
    this.updateCMSUser = flavour.modCL.updateCMSUser;
    this.inviteCMSUser = flavour.modCL.inviteCMSUser;
    this.getCMSUserDetails = flavour.modCL.getCMSUserDetails;
    this.disableCMSUser = flavour.modCL.disableCMSUser;
    this.enableCMSUser = flavour.modCL.enableCMSUser;
    this.approveCMSUser = flavour.modCL.approveCMSUser;
    this.rejectCMSUser = flavour.modCL.rejectCMSUser;
    this.deleteCMSUser = flavour.modCL.deleteCMSUser;

    // behaviour response management endpoints
    this.getBehaviorListApiCall = flavour.modCONVMGMT.getBehaviorListApiCall;
    this.deleteBehaviour = flavour.modCONVMGMT.deleteBehaviour;
    this.disableApiCall = flavour.modCONVMGMT.disableApiCall;
    this.copyApiCall = flavour.modCONVMGMT.copyApiCall;
    this.createBehaviour = flavour.modCONVMGMT.createBehaviour;
    this.saveSEOFile = flavour.modCONVMGMT.saveSEOFile;
    this.updateSEOFile = flavour.modCONVMGMT.updateSEOFile;
    this.publishSEOFile = flavour.modCONVMGMT.publishSEOFile;
    this.getSEOFileInfo = flavour.modCONVMGMT.getSEOFileInfo;
    this.makeBehaviourTemplate = flavour.modCONVMGMT.makeBehaviourTemplate;
    this.uploadFile = flavour.modCONVMGMT.uploadFile;
    this.getBehaviourDetail = flavour.modCONVMGMT.getBehaviourDetail;
    this.getNLPEngines = flavour.modCONVMGMT.getNlpEngines;

    this.getIntentList = flavour.modCONVMGMT.getIntentList;
    this.getIntentDetails = flavour.modCONVMGMT.getIntentDetails;
    this.getSentimentDetails = flavour.modCONVMGMT.getSentimentDetails;
    this.createSentiment = flavour.modCONVMGMT.createSentiment;
    this.getBehaviourNlpEngine = flavour.modCONVMGMT.getBehaviourNlpEngine;
    this.getBehaviourDomains = flavour.modCONVMGMT.getBehaviourDomains;
    this.linkedBehaviours = flavour.modCONVMGMT.linkedBehaviours;
    this.fetchAllBehaviours = flavour.modCONVMGMT.fetchAllBehaviours;
    this.listAllowedBehaviours = flavour.modCONVMGMT.listAllowedBehaviours;
    this.getMultiPageByDomainDirectory = flavour.modCONVMGMT.getMultiPageByDomainDirectory;

    // nlp management endpoints
    this.getNlpListApiCall = flavour.modCONVMGMT.getNlpListApiCall;
    this.getNlpDetails = flavour.modCONVMGMT.getNlpDetails;
    this.saveNlpEngine = flavour.modCONVMGMT.saveNlpEngine;
    this.updateNLPAsset = flavour.modCONVMGMT.updateNLPAsset;
    this.updateNlpEQPrompt = flavour.modCONVMGMT.updateNlpEQPrompt;
    this.getIntentsByBehavior = flavour.modCONVMGMT.getIntentsByBehavior;
    this.approveNLP = flavour.modCONVMGMT.approveNLP;
    this.rejectNLP = flavour.modCONVMGMT.rejectNLP;
    this.changeNlpStatus = flavour.modCONVMGMT.changeNlpStatus;
    this.getNlpByBehavior = flavour.modCONVMGMT.getNlpByBehavior;
    this.fetchAllNlpEngines = flavour.modCONVMGMT.fetchAllNlpEngines;
    this.updateNlpEngine = flavour.modCONVMGMT.updateNlpEngine;
    this.updateNLPProperties = flavour.modCONVMGMT.updateNLPProperties;
    this.updateNlpSettings = flavour.modCONVMGMT.updateNlpSettings;
    this.getNlpEnginesLight = flavour.modCONVMGMT.getNlpEnginesLight;

    // intent endpoint
    this.getINTIntentList = flavour.modINT.getIntentList;
    this.getINTIntentDetails = flavour.modINT.getIntentDetails;
    this.saveIntent = flavour.modINT.saveIntent;
    this.approveIntent = flavour.modINT.approveIntent;
    this.approveResponse = flavour.modINT.approveResponse;
    this.checkDuplicateUtterance = flavour.modINT.checkDuplicateUtterance;
    this.changeIntentStatus = flavour.modINT.changeIntentStatus;
    this.rejectIntent = flavour.modINT.rejectIntent;
    this.searchUtterance = flavour.modINT.searchUtterance;
    this.getAllClientIntents = flavour.modINT.getAllClientIntents;

    // Api definition
    this.externalApiDefinitions = flavour.modAPIDef.externalApiDefinitions;
    this.allDefinitions = flavour.modAPIDef.allDefinitions;
    this.definitionDetails = flavour.modAPIDef.definitionDetails;
    this.updateApiDefinition = flavour.modAPIDef.updateApiDefinition;
    this.deleteApiDefinition = flavour.modAPIDef.deleteApiDefinition;
    this.apiDefinition = flavour.modAPIDef.apiDefinition;
    this.enableDefinition = flavour.modAPIDef.enableDefinition;
    this.disableDefinition = flavour.modAPIDef.disableDefinition;
    this.fetchAllDefinitions = flavour.modAPIDef.fetchAllDefinitions;
    this.listAllowedApiDefinition = flavour.modAPIDef.listAllowedApiDefinition;

    // Entity and Trigger
    this.allentities = flavour.modET.allentities;
    this.triggersOwned = flavour.modET.triggersOwned;
    this.retrieveTrigger = flavour.modET.retrieveTrigger;
    this.trigger = flavour.modET.trigger;
    this.makeTriggerTemplate = flavour.modET.makeTriggerTemplate;
    this.updateTrigger = flavour.modET.updateTrigger;
    this.getAllTriggerTypes = flavour.modET.getAllTriggerTypes;
    this.deleteTrigger = flavour.modET.deleteTrigger;
    this.listMasterTriggers = flavour.modET.listMasterTriggers;
    this.enableTrigger = flavour.modET.enableTrigger;
    this.disableTrigger = flavour.modET.disableTrigger;
    this.addEntity = flavour.modET.addEntity;
    this.updateEntity = flavour.modET.updateEntity;
    this.deleteEntity = flavour.modET.deleteEntity;
    this.landingPageTriggers = flavour.modET.landingPageTriggers;
    this.fetchAllTriggersOwned = flavour.modET.fetchAllTriggersOwned;
    this.triggersOwnedByClient = flavour.modET.triggersOwnedByClient;
    this.fetchResolvedTriggerInfo = flavour.modET.fetchResolvedTriggerInfo;
    this.listAllowedTriggers = flavour.modET.listAllowedTriggers;
    this.fetchAllEntities = flavour.modET.fetchAllEntities;

    this.getJourneyList = flavour.modJB.getJourneyList;
    this.getWorkflowList = flavour.modJB.getWorkflowList;
    this.getSelectedJourney = flavour.modJB.getSelectedJourney;
    this.getIntents = flavour.modJB.getIntents;
    this.getBehaviours = flavour.modJB.getBehaviours;
    this.getNLPEngines = flavour.modJB.getNLPEngines;
    this.createJourney = flavour.modJB.createJourney;
    this.updateJourney = flavour.modJB.updateJourney;
    this.uploadJourney = flavour.modJB.uploadJourney;
    this.duplicateJourney = flavour.modJB.duplicateJourney;
    this.updateStatus = flavour.modJB.updateStatus;
    this.deleteJourney = flavour.modJB.deleteJourney;
    this.getSections = flavour.modJB.getSections;
    this.createSection = flavour.modJB.createSection;
    this.getMilestones = flavour.modJB.getMilestones;
    this.createMilestone = flavour.modJB.createMilestone;
    this.getStackList = flavour.modJB.getStackList;
    this.viewJbById = flavour.modJB.viewJbById;
    this.fetchAllWorkflows = flavour.modJB.fetchAllWorkflows;
    this.getClientWorkflows = flavour.modJB.getClientWorkflows;

    // Machine Learning
    this.getPinnedUtterances = flavour.modML.getPinnedUtterances;
    this.getUnhandledUtterances = flavour.modML.getUnhandledUtterances;
    this.updateUnhandledUtterances = flavour.modML.updateUnhandledUtterances;
    this.deleteUnhandledUtterances = flavour.modML.deleteUnhandledUtterances;
    this.assignUnhandledUtterances = flavour.modML.assignUnhandledUtterances;
    this.deletePinnedUtterances = flavour.modML.deletePinnedUtterances;
    this.updatePinnedUtterances = flavour.modML.updatePinnedUtterances;
    this.assignPinnedUtterances = flavour.modML.assignPinnedUtterances;
    this.delMulUnhandledUtterances = flavour.modML.delMulUnhandledUtterances;
    this.delMulPinnedUtterances = flavour.modML.delMulPinnedUtterances;
    this.getBehavioursByIntent = flavour.modML.getBehavioursByIntent;

    // Live chat endpoints
    this.getAgentList = flavour.modLC.getAgentList;

    // Analytics
    this.getWorkflow = flavour.modANTS.getWorkflow;
    this.getLivechat = flavour.modANTS.getLivechat;
    this.getAI = flavour.modANTS.getAI;
    this.getForms = flavour.modANTS.getForms;
    this.getConsolidatedMetadata = flavour.modANTS.getConsolidatedMetadata;
    this.getWebpage = flavour.modANTS.getWebpage;
    this.getEvent = flavour.modANTS.getEvent;

    // Audit endpoints
    this.fetchAuditEntries = flavour.modADT.fetchAuditEntries;

    // Asset Hub Endpoints
    this.getAssetList = flavour.modAH.getAssetList;
    this.listAssetsUnsafe = flavour.modAH.listAssetsUnsafe;
    this.createAssetList = flavour.modAH.createAssetList;
    this.createAssetFromCloud = flavour.modAH.createAssetFromCloud;
    this.updateAssetList = flavour.modAH.updateAssetList;
    this.updateAssetFromCloud = flavour.modAH.updateAssetFromCloud;
    this.deleteAssetList = flavour.modAH.deleteAssetList;
    this.disableAsset = flavour.modAH.disableAsset;
    this.enableAsset = flavour.modAH.enableAsset;
    this.fetchAllAssets = flavour.modAH.fetchAllAssets;
    this.listLibraryAssets = flavour.modAH.listLibraryAssets;
    this.listUnsplashImages = flavour.modAH.listUnsplashImages;
    this.uploadFileFromCloud = flavour.modAH.uploadFileFromCloud;
    this.retrieveAsset = flavour.modAH.retrieveAsset;

    // RTMC endpoints
    this.listActiveSessions = flavour.modRTMC.listActiveSessions;
    this.agentStatistics = flavour.modRTMC.agentStatistics;
    this.agentWorkload = flavour.modRTMC.agentWorkload;
    this.listAgents = flavour.modRTMC.listAgents;
    this.listActiveChannels = flavour.modRTMC.listActiveChannels;

    // Chameleon Policy Management endpoints
    this.retrievePoliciesByType = flavour.modCPM.retrievePoliciesByType;
    this.listPolicies = flavour.modCPM.listPolicies;
    this.getManagedElements = flavour.modCPM.getManagedElements;
    this.getManagedMethods = flavour.modCPM.getManagedMethods;
    this.createPolicy = flavour.modCPM.createPolicy;
    this.retrieveCMSPolicies = flavour.modCPM.retrieveCMSPolicies;
    this.getPolicyDetails = flavour.modCPM.getPolicyDetails
    this.updatePolicy = flavour.modCPM.updatePolicy;
    this.resetCMSUserPassword = flavour.modCPM.resetCMSUserPassword;

    // Session Mgmt
    this.searchSolrSessions = flavour.modSESSION.searchSolrSessions;
    this.retrieveSession = flavour.modSESSION.retrieveSession;
    this.extractForm = flavour.modSESSION.extractForm;
    this.suggestions = flavour.modSESSION.suggestions;
    this.retrieveAgentName = flavour.modSESSION.retrieveAgentName;
    this.sessionsFacet = flavour.modSESSION.sessionsFacet;
    this.addMultiEntityValue = flavour.modSESSION.addMultiEntityValue;
    this.storeFormAuth = flavour.modSESSION.storeFormAuth;
    this.submitFormAuth = flavour.modSESSION.submitFormAuth;
    this.publishPushNotification = flavour.modSESSION.publishPushNotification;
    this.getPushNotifications = flavour.modSESSION.getPushNotifications;
    this.retrieveSnapshotDetails = flavour.modSESSION.retrieveSnapshotDetails;
    this.evaluateEntityValueAuth = flavour.modSESSION.evaluateEntityValueAuth;
    this.retrieveAllEtValsDecrypted = flavour.modSESSION.retrieveAllEtValsDecrypted;
    this.deleteSolrSessions = flavour.modSESSION.deleteSolrSessions;
  }
}
