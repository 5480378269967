import { ModuleLink } from "@app/core/misctypes/module-link";

export class ModuleList {

  public static modules: ModuleLink[] = [
    new ModuleLink(1, "Behaviour", "/conversationMgmt/behaviour-management"),
    new ModuleLink(1, "Behaviour", "/conversationMgmt/create-behaviour"),
    new ModuleLink(2, "Sessions", "/sessionManagement"),
    new ModuleLink(3, "Clients", "/clientManagement"),
    new ModuleLink(3, "Clients", "/clientManagement/client-create"),
    new ModuleLink(4, "Audit", "/audit"),
    new ModuleLink(5, "Response", "/conversationMgmt/response-management"),
    new ModuleLink(5, "Response", "/conversationMgmt/response-management-detail"),
    new ModuleLink(6, "Users", "/userManagement"),
    new ModuleLink(7, "LC-Chat", "/livechat"),
    new ModuleLink(8, "LC-Telephony", "/telephony"),
    new ModuleLink(9, "LC-Video Calling", "/livechat"),
    new ModuleLink(10, "Analytics", "/analytics"),
    new ModuleLink(11, "RTMC", "/rtmc"),
    new ModuleLink(12, "Intents", "/conversationMgmt/intent-management"),
    new ModuleLink(13, "NLP Engine", "/conversationMgmt/nlp-management"),
    new ModuleLink(13, "NLP Engine", "/conversationMgmt/create-nlp"),
    new ModuleLink(14, "Journey Builder", "/journeyBuilder"),
    new ModuleLink(14, "Journey Builder", "/journeyBuilder/create"),
    new ModuleLink(14, "Journey Builder", "/journeyBuilder/update"),
    new ModuleLink(15, "Entities", "/entity"),
    new ModuleLink(16, "Triggers", "/triggers"),
    new ModuleLink(16, "Triggers", "/trigger-create"),
    new ModuleLink(17, "API Definition", "/apiManagement"),
    new ModuleLink(17, "API Definition", "/api-create"),
    new ModuleLink(18, "Machine Learning", "/machineLearning"),
    new ModuleLink(19, "Asset Hub", "/assets"),
    new ModuleLink(20, "Policies", "/policies"),
    new ModuleLink(21, "DC Drive", "/drive"),
  ];

}
