<mat-form-field class="multi-select-readonly" appearance="legacy" [ngClass]="(displayValue === 'domain' || displayValue === 'countryCodeDto'
  || displayValue === 'currencyDto' || displayValue === 'language' || displayValue === 'name' || displayValue === 'value'
  || displayValue === 'timezoneValue' || (displayValue === 'triggerId' && multiSelectCtrl?.value?.length > 0))
  ? 'multi-select' : 'multi-select m-t-15'">
  <mat-label>{{label}} <span *ngIf="isRequired" class="required">*</span></mat-label>

  <mat-select [formControl]="multiSelectCtrl" (openedChange)="openedChange($event)" (closed)="closedChange()" multiple>
    <mat-select-trigger *ngIf="isSelectTriggerRequired">
      {{ selectTriggerValue && selectTriggerValue.length > 0 ? selectTriggerValue[0] : ''}}
      <span *ngIf="selectTriggerValue && selectTriggerValue.length > 1">
        &nbsp;(+{{selectTriggerValue.length - 1}} more)
      </span>
    </mat-select-trigger>
    <mat-select-trigger *ngIf="!isSelectTriggerRequired && selectedSequencedItem.length > 0">
      {{selectedSequencedItem}}
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [formControl]="searchCtrl" placeholderLabel="Search..."
        noEntriesFoundLabel="'No match found'">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option #allSelected *ngIf="isSelectAllRequired && !searchText && filteredOptions.length > 0"
      [value]="'selectAll'" (click)="toggleAllSelection()">
      Select All
    </mat-option>
    <mat-option *ngIf="isNoneRequired" value="">None</mat-option>
    <div cdkDropList (cdkDropListDropped)="changeOptionSequence($event)">
      <mat-option [ngClass]="{'readonly-options' : isReadOnlyDropdown}" *ngFor="let option of filteredOptions"
        [value]="option[displayValue]" (click)="toggleOneOption(option[displayValue])" cdkDrag>
        {{option[displayName]}}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>